import React, { useEffect } from "react";

import { useDispatch } from "react-redux";
import { loginUser } from "../Helpers/Redux/StateSlice/user";

import SideBar from "./sidebar";
import NavBar from "./navbar";
import { getApi } from "../Helpers/Axios";
import { ToastContainer } from "react-toastify";

const Index = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    handleUserVerify();
    handleAdminInfo();
  }, []);

  let handleUserVerify = () => {
    let token = localStorage.getItem(process.env.REACT_APP_SECRET_KEY);
    if (token === undefined || token === "" || token === null) {
      window.location.href = "/";
    }
  };

  let handleAdminInfo = async () => {
    const response = await getApi("get-admin", true);
    dispatch(loginUser(response?.data));
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <NavBar />
      <SideBar />
      <div className="container_wrapper">{children}</div>
    </>
  );
};

export default Index;
